import React, { ComponentPropsWithRef, FC } from 'react';
import { Text } from '@components/Text/Text.component';
import { TextProps } from '@components/Text/Text.types';
import { Validator } from '@form/business/form.types';
import { Spacing } from '@styles/Spacing.style';
import classnames from 'classnames';
import styles from './FormHints.module.scss';

export type HintProps = {
  isHintVisible?: boolean;
  // TODO : leverage the useFormState hook to have the information from the error object instead of passing the validators
  validators?: Validator[];
  pushContentBelow?: boolean;
  isFooter?: boolean;
};

type Props = ComponentPropsWithRef<'div'> &
  HintProps & {
    textProps?: TextProps;
  };

function sortValidFirst(a: Validator, b: Validator) {
  if (a.isValid) {
    return 1;
  }
  if (b.isValid) {
    return -1;
  }
  return 0;
}

function getFlavour(type: Validator['type']): TextProps['flavour'] {
  switch (type) {
    case 'error':
      return 'red';
    case 'success':
      return 'green';
    default:
      return 'dark';
  }
}

const FormHints: FC<Props> = ({
  validators,
  isFooter = false,
  isHintVisible,
  className,
  pushContentBelow = false,
  textProps = {},
  ...rest
}) => {
  if (!validators) {
    return null;
  }
  const isVisible = isHintVisible && validators.some(v => !v.isValid);
  const rootClassname = classnames(styles['form-hints'], isVisible ? styles['visible'] : styles['hidden'], isFooter ? styles['footer'] : '', className);

  const { flavour: textFlavour, tag, variant, ...restTextProps } = textProps;

  return (
    <div
      className={rootClassname}
      style={pushContentBelow && isVisible ? { height: `${Spacing.m}px` } : undefined}
      {...rest}
    >
      {
        validators.sort(sortValidFirst).map(v => {
          const hintClassName = classnames(v.isValid ? styles['hidden'] : styles['visible'], v.type || styles['error']);
          const type = v.type || 'error';
          const flavour = textFlavour || getFlavour(type);
          return (
            <Text
              key={v.message}
              className={hintClassName}
              flavour={flavour}
              tag={tag || 'p'}
              variant={variant || 'tiny'}
              {...restTextProps}
            >
              {v.message}
            </Text>
          );
        })[0]
      }
    </div>
  );
};

export { FormHints };
