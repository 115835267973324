import { AnimatedButton } from './AnimatedButton/AnimatedButton.component';
import { ComponentProps } from 'react';
import { TextPropsOptionalVariant } from '@components/Text/Text.types';

export enum FlatButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  underlined = 'underlined',
}

export type FlatButtonProps = ComponentProps<typeof AnimatedButton> & {
  isPro?: boolean;
  isSmall?: boolean;
  isExtraSmall?: boolean;
  isDark?: boolean;
  isDarkGreen?: boolean;
  isLightGreen?: boolean;
  isBleuTurquoise?: boolean;
  isWithIcon?: boolean;
  textProps?: TextPropsOptionalVariant;
  wrapperClassName?: string;
};
