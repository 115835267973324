import React, { ComponentPropsWithRef, FC, PropsWithChildren } from 'react';
import { IconButton } from '@components/buttons/IconButton/IconButton.component';
import { HelpIcon } from '@components/icons/help.icon';
import { useModal } from '@layout/business/Layout.utils';
import { PlcColor } from '@styles/colors';

interface Props extends ComponentPropsWithRef<'button'> {
  title?: string;
  popupTitleI18nKey?: string;
}

const HelpIconButton: FC<PropsWithChildren<Props>> = ({ title, popupTitleI18nKey = '', children, ...rest }) => {
  const modal = useModal();

  return (
    <IconButton
      onClick={() => {
        modal.show({
          children,
          className: 'sign-in',
          title,
          titleI18nKey: popupTitleI18nKey,
        });
      }}
      type="button"
      {...rest}
    >
      <HelpIcon fillColor={PlcColor.GREY_CLOUD_MEDIUM} />
    </IconButton>
  );
};

export { HelpIconButton };
