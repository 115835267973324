import { AnimatedButton } from '../AnimatedButton/AnimatedButton.component';
import { FlatButtonProps, FlatButtonVariant } from '../Buttons.type';
import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { TextPropsOptionalVariant } from '@components/Text/Text.types';
import { TextWithArrow } from '@components/Text/TextWithArrow.component';
import classnames from 'classnames';

function buildTextFlavour(
  flavour: TextPropsOptionalVariant['flavour'],
  variant: FlatButtonVariant,
  isPro: boolean,
  isLightGreen: boolean,
  isDarkGreen: boolean
) {
  if (!flavour) {
    if (isLightGreen) {
      return 'black';
    }
    if (isDarkGreen) {
      return 'light';
    }
    return variant === FlatButtonVariant.primary && !isPro ? 'light' : 'dark';
  }
  return flavour;
}

function buildTextVariant(
  v: TextPropsOptionalVariant['variant'],
  isDarkGreen: boolean,
  isLightGreen: boolean,
  isBleuTurquoise: boolean,
  isSmall: boolean
) {
  if (!v) {
    if (isDarkGreen || isLightGreen || isBleuTurquoise) {
      return 'new-bold-p';
    }
    return isSmall ? 'small' : 'p';
  }
  return v;
}

const FlatButton: FC<PropsWithChildren<FlatButtonProps & { variant: FlatButtonVariant }>> = forwardRef(
  (
    {
      isPro = false,
      variant,
      isSmall = false,
      isExtraSmall = false,
      children,
      className,
      isDark = false,
      isDarkGreen = false,
      isLightGreen = false,
      isBleuTurquoise = false,
      isWithIcon = false,
      textProps = {},
      withAnimation,
      ...rest
    },
    buttonRef
  ) => {
    const { flavour: textFlavour, withArrow = false, variant: textVariant, ...restTextProps } = textProps;
    const rootClassName = classnames(
      'flat-button',
      variant,
      isDark ? 'dark' : '',
      isLightGreen ? 'light-green' : '',
      isDarkGreen ? 'dark-green' : '',
      isBleuTurquoise ? 'bleu-turquoise' : '',
      isPro ? 'pro' : '',
      isSmall ? 'small' : '',
      isExtraSmall ? 'extra-small' : '',
      textFlavour,
      className
    );

    return (
      <AnimatedButton
        ref={buttonRef}
        className={rootClassName}
        withAnimation={withAnimation || variant === FlatButtonVariant.underlined ? false : true}
        {...rest}
      >
        {isWithIcon && children}
        <TextWithArrow
          flavour={buildTextFlavour(textFlavour, variant, isPro, isLightGreen, isDarkGreen)}
          tag="span"
          variant={buildTextVariant(textVariant, isDarkGreen, isLightGreen, isBleuTurquoise, isSmall)}
          withArrow={withArrow}
          {...restTextProps}
        >
          {!isWithIcon && children}
        </TextWithArrow>
      </AnimatedButton>
    );
  }
);

const PrimaryButton: FC<FlatButtonProps> = forwardRef((props, buttonRef) => (
  <FlatButton ref={buttonRef} variant={FlatButtonVariant.primary} {...props} />
));
const SecondaryButton: FC<FlatButtonProps> = forwardRef((props, buttonRef) => (
  <FlatButton ref={buttonRef} variant={FlatButtonVariant.secondary} {...props} />
));
const UnderlinedButton: FC<FlatButtonProps> = forwardRef((props, buttonRef) => (
  <FlatButton ref={buttonRef} variant={FlatButtonVariant.underlined} {...props} />
));
export { PrimaryButton, SecondaryButton, UnderlinedButton, FlatButton };
