import { BasicButton } from '../BasicButton/BasicButton.component';
import React, { ComponentPropsWithRef, FC, forwardRef, PropsWithChildren, useState } from 'react';
import classnames from 'classnames';
import styles from './AnimatedButton.module.scss';

const RADIUS = 100;

const AnimatedButton: FC<PropsWithChildren<ComponentPropsWithRef<'button'> & { withAnimation?: boolean }>> = forwardRef(
  ({ children, className, onClick, withAnimation = true, ...rest }, buttonRef) => {
    const [isActive, setIsActive] = useState(false);
    const coordDefault = { x: undefined, y: undefined };
    const [coord, setCoord] = useState<{ x?: number; y?: number }>(coordDefault);

    const stopActive = () => {
      setIsActive(false);
      setCoord(coordDefault);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (onClick && (event.key === 'Enter' || event.key === ' ')) {
        onClick(event as unknown as React.MouseEvent<HTMLButtonElement>);
      }
    };

    const onMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
      const { currentTarget } = event;
      const rect = currentTarget.getBoundingClientRect() as DOMRect;
      setIsActive(true);
      setCoord({ x: event.clientX - rect.x, y: event.clientY - rect.y });
    };

    const onMouseUp = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(event);
      }
      stopActive();
    };

    const left = coord.x ? `${coord.x - RADIUS}px` : undefined;
    const top = coord.y ? `${coord.y - RADIUS}px` : undefined;

    const rootClassname = classnames(
      styles['animated-button'],
      className,
      withAnimation ? styles['with-animation '] : null
    );
    return (
      <BasicButton
        ref={buttonRef}
        className={rootClassname}
        onKeyDown={onKeyDown}
        onMouseDown={onMouseDown}
        onMouseLeave={stopActive}
        onMouseUp={onMouseUp}
        {...rest}
      >
        {children}

        {isActive && left && top ? (
          <div
            className={styles.ripple}
            style={{
              transform: `translate(${left}, ${top})`,
            }}
          >
            <div className={styles.round} />
          </div>
        ) : null}
      </BasicButton>
    );
  }
);

export { AnimatedButton };
